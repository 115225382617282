import React from 'react';

const NFTCard = ({ nft }) => {
  return (
    <div className="mt-6 card bg-white rounded-lg shadow-md overflow-hidden transition-shadow duration-300 ease-in-out hover:shadow-lg">
      <img className="card-image w-full h-56 object-cover" src={nft.src} alt={nft.title} />
      <div className="card-content p-4">
        <h3 className="card-title text-lg font-semibold mb-2">{nft.title}</h3>
        <p className="card-description text-gray-700 text-sm">{nft.description}</p>
        {/* <a href={nft.link} className="link text-blue-500 hover:underline">Learn More</a> */}
      </div>
    </div>
  );
};

export default NFTCard;

