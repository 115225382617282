import axios from "redaxios";


export const pinJsonToIPFS = async (data) => {
  const PINATA_KEY = "3bf8f9bd39401d8f039e"
  const PINATA_SECRET = "5d24a599abb7b85841209a3be4c3d651a6dded6d2992c5a2fe5035b7d2303502"

  try {
    return (
      await axios({
        method: "post",
        url: "https://api.pinata.cloud/pinning/pinJsonToIPFS",
        data: {
          pinataContent: data,
          pinataOptions: {
            cidVersion: 1,
            wrapWithDirectory: false,
          },
        },
        headers: {
          // Authorization: PINATA_JWT,
          pinata_api_key: PINATA_KEY,
          pinata_secret_api_key: PINATA_SECRET,
        },
      })
    ).data;
  } catch (error) {
    console.log("Error sending JSON to IPFS:");
    console.error(error);
  }
};

export default pinJsonToIPFS;
