import axios from "redaxios";
// import { API_ROOT } from "../config";
const endpoint = "https://4mdympe1r8.execute-api.us-east-2.amazonaws.com/default/mrgiggles";

export const generateBoulevardAI = async ({
  model = 0,
  prompt,
}) => {
  try {
    console.log("running MrGiggles...");
    // if (model == null  prompt == null  prompt === "") {
    //   throw new Error(
    //     "generateBoulevardAI.Error: must set model, and `prompt`"
    //   );
    // }
    const res = await axios.post(`${endpoint}`, {
      model,
      prompt,
    });
    console.log("res: ", res);
    return res;
  } catch (error) {
    console.error("[generateBoulevardAI]", error);
  }
};
export default generateBoulevardAI;
