import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
// import { Gallery, NftGallery } from './components/Gallery';
import NftGallery from "./components/NftGallery";
import MintModal from "./components/MintModal";
import BasicMintingContractABI from "./contracts/BasicMintingContract.json";
import Button from "./components/ui/Button";
import { contracts } from "./data/config";
import contractABI from "./contracts/BasicMintingContract.json";

function getNetworkName(chainId) {
  const mapping = {
    // "421614": "arbitrum-sepolia",
    // "84532": "base-sepolia",
    "0x66eee": "arbitrum-sepolia",
    "0x14a34": "base-sepolia",
  };
  return mapping[chainId];
}

const App = () => {
  const [isMintModalOpen, setMintModalOpen] = useState(false);

  const [userAccount, setUserAccount] = useState(null);
  const [isConnected, setIsConnected] = useState(false); // New state to track connection status
  const [chainId, setChainId] = useState(null);
  const [networkName, setNetworkName] = useState();
  const [contractAddress, setContractAddress] = useState();

  const [formData, setFormData] = useState({});

  const openMintModal = () => setMintModalOpen(true);
  const closeMintModal = () => setMintModalOpen(false);
  const toggleMintModal = () => setMintModalOpen(!isMintModalOpen);

  const requestAccount = async () => {
    try {
      const [account] = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setUserAccount(account);
      setIsConnected(true); // Set connection status to true

      // Get the current chain ID
      const _chainId = await window.ethereum.request({ method: "eth_chainId" });
      console.log("Current chain ID:", _chainId);
      setChainId(_chainId);

      // Convert chain ID to a human-readable network name, if needed
      const _networkName = getNetworkName(_chainId);
      console.log("Connected to network:", _networkName);
      setNetworkName(_networkName);

      const _contractAddress = contracts[_networkName];
      console.log("Contract address:", _contractAddress);
      setContractAddress(_contractAddress);
    } catch (error) {
      console.error("Error requesting account:", error.message);
    }
  };

  const [nfts, setNfts] = useState([]);

  async function fetchNFTs() {
    if (typeof window.ethereum !== "undefined") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        contractAddress,
        contractABI.abi,
        provider
      );

      const nftsData = [];

      for (let tokenId = 1; tokenId <= 100; tokenId++) {
        try {
          if ([7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(tokenId)) {
            continue;
          }
          const tokenURI = await contract.tokenURI(tokenId);
          const metaResponse = await fetch(tokenURI);
          const metadata = await metaResponse.json();
          // console.debug(`Fetched metadata for tokenId ${tokenId}:`, metadata);

          // Make sure to check if 'metadata.nftName', 'metadata.nftDescription', and 'metadata.image' exist
          if (
            "nftName" in metadata &&
            "nftDescription" in metadata &&
            "image" in metadata
          ) {
            nftsData.push({
              tokenId,
              src: metadata.image,
              title: metadata.nftName,
              description: metadata.nftDescription,
            });
          } else {
            console.error(
              `Missing required properties in metadata for tokenId ${tokenId}`
            );
          }
        } catch (error) {
          if (error?.message?.includes("ERC721NonexistentToken")) {
            // Break the loop on the first error
            console.log(`ERC721NonexistentToken`);
            break;
          }
          console.error(
            `Error fetching metadata for tokenId ${tokenId}:`,
            error.message
          );
        }
      }

      setNfts(nftsData.reverse()); // Reverse the array before setting into state to show the gallery in reverse order
    }
  }

  useEffect(() => {
    if (contractAddress == null) {
      return;
    }
    fetchNFTs();
  }, [contractAddress]);

  useEffect(() => {
    requestAccount();
  }, []);

  const handleConnect = () => {
    requestAccount();
  };

  const handleFormDataChange = (newFormData) => {
    setFormData({ ...formData, ...newFormData });
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <div className="flex items-center">
            <h1 className="text-3xl font-bold text-gray-900">My NFT Gallery</h1>
            <span className="ml-4 px-3 py-1 bg-peach-200 text-peach-800 text-sm font-semibold rounded-full flex items-center">
              Built with <a href="https://justpeachy.ai" target="_blank" rel="noreferrer"><span className="font-bold ml-2">🍑</span><span className="font-bold ml-1">JustPeachy.ai</span></a>
            </span>
          </div>
          <div>
            {isConnected ? (
              <span className="text-green-500 font-semibold mr-4">
                Connected
              </span>
            ) : (
              <Button
                onClick={handleConnect}
                className="m-2 bg-indigo-500 text-white"
              >
                Connect
              </Button>
            )}
            <Button
              onClick={openMintModal}
              className="m-2 bg-green-500 text-white"
            >
              Mint New Item
            </Button>
          </div>
        </div>
      </header>

      <main>
        <div className="container mx-auto">
          <NftGallery nfts={nfts} />
          {isMintModalOpen && (
            <MintModal
              isOpen={isMintModalOpen}
              onClose={closeMintModal}
              toggleModal={toggleMintModal}
              onFormDataChange={handleFormDataChange}
              userAccount={userAccount}
              contractAddress={contractAddress}
              fetchNFTs={fetchNFTs}
            />
          )}
        </div>
      </main>
    </div>
  );
};

export default App;
