export async function fetchFileDataFromImageUrl(imageUrl) {
  const proxyUrl = "https://cors-anywhere.herokuapp.com/";
  return fetch(proxyUrl + imageUrl)
    .then((response) => response.blob()) // or .text() if you're fetching a text file
    .then((fileData) => {
      // fileData is a Blob representing the file's data
      // You can now use this Blob however you need
      console.log(fileData);
      // Here you can handle the Blob (fileData) depending on your requirements

      // Create a file from the blob
      const filename = "downloadedFile.png"; // Set the file name (you might want to derive this from the URL or content-disposition header)
      const file = new File([fileData], filename, { type: fileData.type });

      return file;
    })
    .catch((error) => console.error("Error:", error));
  // Fetch the file
  // return fetch(imageUrl)
  //   .then(response => {
  //     // Check if the request was successful
  //     if (response.ok) {
  //       return response.blob(); // Use response.text() for text files
  //     }
  //     throw new Error('Network response was not ok.');
  //   })
  //   .then(fileData => {
  //     // fileData is a Blob representing the file's data
  //     // You can now use this Blob however you need
  //     console.log(fileData);
  //     // Here you can handle the Blob (fileData) depending on your requirements
  //   })
  //   .catch(error => {
  //     console.error('There has been a problem with your fetch operation:', error);
  //   });
}

// export function localFileFromImageUrl(imageUrl) {
//   // Fetch the image
//   fetch(imageUrl)
//     .then((response) => response.blob()) // Convert response to Blob
//     .then((blob) => {
//       // Create a new object URL for the blob
//       const url = window.URL.createObjectURL(blob);

//       // Create a link and set the URL as the href
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = "downloaded_image.png"; // Name the download file

//       // Append the link to the body
//       document.body.appendChild(a);

//       // Click the link to start the download
//       a.click();

//       // Remove the link after starting the download
//       document.body.removeChild(a);

//       // Revoke the object URL to free up resources
//       window.URL.revokeObjectURL(url);
//     })
//     .catch(console.error); // Log any errors
// }

// export default localFileFromImageUrl;
