// import axios from "redaxios";

const apiKey = process.env.REACT_APP_OPENAI_API_KEY; // Replace 'your-api-key-here' with your actual OpenAI API key
const url = "https://api.openai.com/v1/images/generations"; // DALL-E endpoint

// Set up the request headers
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${apiKey}`,
};

async function generateDalleImageUrl({ prompt = "pixel art of people in a hot tub" }) {
  try {
    // Set up the request data
    const data = {
      prompt,
      n: 1, // Number of images to generate
      // size: "256x256", // Image size, can be "1024x1024", "512x512", etc.
      // size: "512x512", // Image size, can be "1024x1024", "512x512", etc.
      size: "1024x1024", // Image size, can be "1024x1024", "512x512", etc.
      // size: "2048x2048", // Image size, can be "1024x1024", "512x512", etc.
      // size: "4096x4096", // Image size, can be "1024x1024", "512x512", etc.
    };
    
    // Make the POST request
    return fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("result:", result);
        return result?.data[0]?.url;
      })
      .catch((error) => {
        console.error("Error:", error); // Handle any errors
      });
    // return axios
    //   .post(url, data, { headers: headers })
    //   .then((response) => {
    //     console.debug(response);
    //     const result = response?.data;
    //     console.log(result); // Handle the response from the API
    //     return result?.data[0]?.url
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error); // Handle any errors
    //   });
  } catch (error) {
    console.error("Error:", error); // Handle any errors
  }
}

export default generateDalleImageUrl;
