import OpenAI from "openai";

const openai = new OpenAI({
  // apiKey: process.env['REACT_APP_OPENAI_API_KEY'], // This is the default and can be omitted
  apiKey: "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", // This is the default and can be omitted
  dangerouslyAllowBrowser: true,
});

// export async function handler(event, context) {
//   console.log("event: ", event);
//   let body = event?.body;
//   console.log("body: ", body);
//   if (body == null) {
//     const response = {
//       statusCode: 204,
//       body: JSON.stringify("Body is null..."),
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Headers": "Content-Type",
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
//         Connection: "keep-alive",
//         "Access-Control-Max-Age": 86400,
//       },
//     };
//     return response;
//   }
//   const parsedBody = typeof body === "string" ? JSON.parse(body) : body;
//   console.log("parsedBody: ", parsedBody);
//   const model = parsedBody?.model;
//   console.log("model: ", model);
//   const prompt = parsedBody?.prompt;
//   console.log("prompt: ", prompt);
//   let response;
//   if (model == 0) {
//     response = await chatCompletion(prompt);
//   } else {
//     // response = await imageGeneration(prompt);
//   }
//   return response;
// }

export async function chatCompletion(question) {
  try {
    // const messages = [];
    // messages.push({ role: "user", content: question });
    // console.log("messages: ", messages);
    // const params = {
    //   model: "gpt-4-turbo-preview",
    //   messages: messages,
    //   //model: "text-davinci-003",
    //   // prompt: question,
    //   temperature: 0.05,
    //   max_tokens: 1000,
    //   top_p: 0.5,
    //   frequency_penalty: 0,
    //   presence_penalty: 0,
    // };
    // console.log("params: ", params);
    // // const completion = await openai.createCompletion(params);
    // const completion = await openai.chat.completions.create(params);
    // // const completion_text = completion.data.choices[0].message.content;
    // console.log("completion: ", completion);
    // console.log("completion.data: ", completion.data);
    // console.log("completion.data.choices: ", completion.data.choices);
    // const davinci_text = completion.data.choices[0].text;
    return {
      statusCode: 200,
      // body: JSON.stringify({ response: davinci_text }),
      // headers: {
      //   "Content-Type": "application/json",
      //   "Access-Control-Allow-Headers": "Content-Type",
      //   "Access-Control-Allow-Origin": "*",
      //   "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
      // },
    };
  } catch (error) {
    console.log("Error: ", error);
  }
}

export async function imageGeneration(prompt) {
  try {
    const response = await openai.images.generate({
      prompt,
      n: 10,
      size: "1024x1024",
    });
    return {
      statusCode: 200,
      body: JSON.stringify({ response: response.data.data[0].url }),
      // headers: {
      //   "Content-Type": "application/json",
      //   "Access-Control-Allow-Headers": "Content-Type",
      //   "Access-Control-Allow-Origin": "*",
      //   "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
      // },
    };
  } catch (error) {
    console.log("Error: ", error);
  }
}
