// NftGallery.js

import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import NFTCard from './NFTCard';

// Assuming the ABI includes a function `tokenURI(tokenId)` to get the NFT metadata URI
import contractABI from '../contracts/BasicMintingContract.json';
// import { contracts } from '../data/config';

// const contractAddress = '0x315466ea5fa2a636d463372b5be7b19ba87a8f25';

const NftGallery = ({ isLoading = true, nfts }) => {
  isLoading = (isLoading || nfts?.length === 0) && !nfts?.length > 0
  return (
    <div className="gallery-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-8">
      {isLoading && <p>Loading...</p>}
      {nfts.map(nft => (
        <NFTCard key={nft.tokenId} nft={nft} />
      ))}
    </div>
  );
};

export default NftGallery;
