import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import generatePixelArt from "../services/generatePixelArt";
import BasicMintingContractABI from "../contracts/BasicMintingContract.json";
import { pinFileToIPFS, pinJsonToIPFS, PixelArtGenerator } from "../services";
import generateDalleImage from "../services/generateDalleImage";
import localFileFromImageUrl, {
  fetchFileDataFromImageUrl,
} from "../services/localFileFromImageUrl";
import {
  chatCompletion,
  imageGeneration,
} from "../services/generateImageFromChatCompletions";
import generateBoulevardAI from "../services/mrGiggles";

const MintModal = ({
  isOpen,
  onClose,
  toggleModal,
  onFormDataChange,
  userAccount,
  contractAddress,
  fetchNFTs,
}) => {
  const [nftName, setNftName] = useState("people in a hot tub");
  const [nftDescription, setNftDescription] = useState("");
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [ipfsHash, setIpfsHash] = useState("");

  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedArtData, setGeneratedArtData] = useState("");

  const [isMinting, setIsMinting] = useState(false);
  const [isMinted, setIsMinted] = useState(false);

  useEffect(() => {
    // No automatic minting on userAccount change
  }, [userAccount]);

  const handleImageChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const uploadToIPFS = async () => {
    console.log("uploadingToIPFS...");
    // if (!file) {
    //   console.error("No image to upload.");
    //   return null;
    // }

    try {
      // Use the pinFileToIPFS function to upload the image file
      let imageUri;
      console.log("FILE: ", file);
      if (file) {
        imageUri = await pinFileToIPFS({ file });
        console.log("imageUri:", imageUri);
      } else if (imagePreviewUrl) {
        imageUri = imagePreviewUrl;
        console.log("imageUri:", imageUri);
      }

      // const imageUri = await pinFileToIPFS({ file });
      // console.log('imageUri:', imageUri);

      // You can add additional data to the JSON object if needed
      // const imageUri = "https://oaidalleapiprodscus.blob.core.windows.net/private/org-AvZkBWbSlv1JVF3WmxephDx4/user-VzSY5eeCeunIFRqgzvVGDoKz/img-P1GYHFxyjiFMEAJjLPjqNivb.png?st=2024-03-01T21%3A57%3A27Z&se=2024-03-01T23%3A57%3A27Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2024-03-01T15%3A15%3A15Z&ske=2024-03-02T15%3A15%3A15Z&sks=b&skv=2021-08-06&sig=gu%2BTDf5QDjrIH5B%2B7q7P76HbbcIlddzobfqhZqveODw%3D";

      const jsonData = {
        nftName,
        nftDescription,
        image: imageUri,
      };

      // Use the pinJsonToIPFS function to upload JSON data to IPFS
      const tokenURI = await pinJsonToIPFS(jsonData);
      console.log("tokenURI:", `https://ipfs.io/ipfs/${tokenURI.IpfsHash}`);

      // Return the IPFS hash for further use
      return tokenURI.IpfsHash;
    } catch (error) {
      console.error("Error uploading to IPFS:", error.message);
      return null;
    }
  };

  async function handleGenerateArt() {
    try {
      setIsGenerating(true);

      // const prompt = "pixel art of people in a hot tub";
      const prompt = `pixel art of ${nftName}`;

      // const result = chatCompletion("pixel art of people in a hot tub")
      // console.log("result: ", result);

      // const result = await imageGeneration(prompt);
      // console.log("result: ", result);

      // MR GIGGLES
      // const result = await generateBoulevardAI({ model: 1, prompt });
      // console.log("result: ", result);

      // const image = generatePixelArt()
      // setFile(image);

      const imageUrl = await generateDalleImage({ prompt });
      console.log("imageUrl: ", imageUrl);
      setImagePreviewUrl(imageUrl);

      const localFile = await fetchFileDataFromImageUrl(imageUrl);
      console.log("localFile: ", localFile);
      setFile(localFile);
    } catch (error) {
      console.error("Error generating art:", error.message);
    } finally {
      setIsGenerating(false);
    }
  }

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const nftName = e.target.nftName.value;
  //   const nftDescription = e.target.nftDescription.value;

  //   const formData = {
  //     nftName,
  //     nftDescription,
  //     file,
  //   };

  //   // const ipfsHash = await uploadToIPFS();
  //   // if (ipfsHash) {
  //   //   setIpfsHash(ipfsHash);
  //   //   onFormDataChange({ ...formData, ipfsHash });
  //   // }
  // };

  const handleMint = async () => {
    try {
      if (
        !userAccount ||
        !contractAddress ||
        typeof window.ethereum === "undefined"
      ) {
        console.error(
          "Invalid user account or missing contract address or Ethereum provider."
        );
        return;
      }

      setIsMinting(true)
      
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          contractAddress,
          BasicMintingContractABI.abi,
          signer
        );

        // Constructing the tokenURI with title, description, and IPFS hash

        const formData = {
          nftName,
          nftDescription,
          file,
        };

        const ipfsHash = await uploadToIPFS();
        if (ipfsHash) {
          setIpfsHash(ipfsHash);
          onFormDataChange({ ...formData, ipfsHash });
        }

        const tokenURI = `https://ipfs.io/ipfs/${ipfsHash}`;
        console.log("tokenURI: ", tokenURI);

        const transaction = await contract.safeMint(userAccount, tokenURI);
        await transaction.wait();
        setIsMinted(true);
        alert("NFT Minted Successfully!");

        await fetchNFTs();
      } catch (error) {
        console.error("Error while minting NFT:", error.message);
      }
    } catch (error) {
      console.error("Error while minting NFT:", error.message);
    } finally {
      setIsMinting(false);
    }
  };

  const closeModal = () => {
    setIsMinted(false);
    onClose();
  };

  if (!isOpen && !isMinted) return null;

  const handleModalClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      toggleModal();
    }
  };

  const handleModalContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="fixed inset-0 bg-gray-900 bg-opacity-70 z-50 overflow-auto"
      onClick={handleModalClickOutside}
    >
      <div className="flex items-center justify-center min-h-screen">
        <div
          className="bg-white p-8 rounded-xl shadow-2xl max-w-md mx-auto space-y-4 relative overflow-hidden"
          onClick={handleModalContentClick}
        >
          <button
            className="absolute top-0 right-0 m-4 text-3xl"
            onClick={toggleModal}
          >
            &times;
          </button>
          <h2 className="text-lg font-semibold text-gray-900">Mint Your NFT</h2>
          {isMinted ? (
            <p className="text-green-500">NFT Minted Successfully!</p>
          ) : (
            <>
              <p className="text-gray-700">
                Fill in the details below to mint your new NFT.
              </p>
              <form onSubmit={handleMint} className="space-y-4 py-2">
                <label
                  htmlFor="nftName"
                  className="block text-sm font-medium text-gray-700"
                >
                  NFT Title
                </label>
                <input
                  type="text"
                  id="nftName"
                  name="nftName"
                  value={nftName}
                  onChange={(e) => setNftName(e.target.value)}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                />

                <label
                  htmlFor="nftDescription"
                  className="block text-sm font-medium text-gray-700"
                >
                  NFT Description
                </label>
                <textarea
                  id="nftDescription"
                  name="nftDescription"
                  value={nftDescription}
                  onChange={(e) => setNftDescription(e.target.value)}
                  rows="3"
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                ></textarea>

                {file ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Generated Pixel Art"
                    className="max-w-md mx-auto object-fill mt-4"
                  />
                ) : imagePreviewUrl ? (
                  <div className="mt-4 overflow-hidden">
                    <p className="text-sm font-medium text-gray-700">
                      Image Preview:
                    </p>
                    <img
                      src={imagePreviewUrl}
                      alt="NFT Preview"
                      className="max-w-md mx-auto object-fill"
                    />
                  </div>
                ) : null}
                <button
                  type="button"
                  onClick={handleGenerateArt}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {isGenerating ? "Generating..." : "Generate Pixel Art"}
                </button>

                <button
                  type="button"
                  onClick={handleMint}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {isMinting ? "Minting..." : "Mint NFT"}
                </button>
              </form>
            </>
          )}
          {/* <button
            onClick={closeModal}
            className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-red-600 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Close
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default MintModal;
