import axios from "redaxios";

export const pinFileToIPFS = async ({ file }) => {
  const PINATA_KEY = "3bf8f9bd39401d8f039e"
  const PINATA_SECRET = "5d24a599abb7b85841209a3be4c3d651a6dded6d2992c5a2fe5035b7d2303502"

  if (file == null) {
    console.warn("pinFileToIPFS called with null value:", { file });
    return;
  }
  try {
    const formData = new FormData();
    formData.append("file", file);

    // const contentType = `multipart/form-data; boundary=${formData.getBoundary()}`;
    // console.log("contentType:", contentType);

    const resFile = await axios({
      method: "post",
      url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
      data: formData,
      // data: {
      //   file: formData,
      //   pinataOptions: {
      //     cidVersion: 1,
      //     wrapWithDirectory: false,
      //   },
      // },
      headers: {
        // Authorization: PINATA_JWT,
        pinata_api_key: PINATA_KEY,
        pinata_secret_api_key: PINATA_SECRET,
      },
    });

    const ipfsURI = `https://ipfs.io/ipfs/${resFile.data.IpfsHash}`;
    console.log("ipfsURL: ", ipfsURI);

    return ipfsURI;
  } catch (error) {
    console.log("Error sending File to IPFS:");
    console.error(error);
  }
};

export default pinFileToIPFS;
